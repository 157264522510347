import Vue from 'vue'
import App from './App.vue'

// Vue.config.productionTip = false
import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'

Vue.use (vueCustomElement)

document.addEventListener("DOMContentLoaded",()=> {
    Vue.customElement('rm-feedback-widget', App)
});
