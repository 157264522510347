<template>
    <div id="rm-feedback-widget" class="rm-widget" v-if="displayApp"
         :style="{ fontSize: data.design.font_size + 'px' }"
         :class="{topLeft : data.design.position === 1,
        topRight : data.design.position === 2,
        leftBottom : data.design.position === 3,
        rightBottom : data.design.position === 4,
        fontLato: data.design.font_family === 'Lato',
        fontRoboto: data.design.font_family === 'Roboto',}">
        <div class="rm-widget__popup-btn"
             v-if="!displayForm" @click="showForm()"
             :style="{ backgroundColor: data.design.background_color, color: data.design.font_color}">
            <div>{{ data.title }}</div>
        </div>
        <div class="rm-widget__form" v-if="displayForm" :class="{ open: displayForm }">
            <div class="rm-widget__header">
                <div class="rm-widget__tab-count">
                    <span v-show="!displayAltTab && !displayResult">1/2</span>
                    <span v-show="displayAltTab && !displayResult">2/2</span>
                </div>
                <div class="rm-widget__title">
                    <span v-if="!data.design.image">{{ data.title }}</span>
                    <img v-if="data.design.image" :src="data.design.image" alt="logo">
                </div>
                <div class="rm-widget__close-btn" @click="closeApp(); addStorageRecord()">
                    &#10006;
                </div>
            </div>
            <div class="rm-widget__body"
                 :style="{ backgroundColor: data.design.background_color, color: data.design.font_color}">
                <div class="rm-widget__tab" v-if="displayTabs">

                    <div class="rm-widget__back-btn" v-if="displayAltTab" @click="goBack()"><span>&#8406; </span>назад</div>

                    <div class="rm-widget__tab-question">
                        {{ data.quiz.question_1 }}
                    </div>
                    <div class="rm-widget__btn-wrapper" v-if="!displayAltTab">
                        <div class="rm-widget__btn"
                             @click="clickedYes(); showResult(); postData(); addStorageRecord()">{{ data.quiz.answer_1 }}
                        </div>
                        <div class="rm-widget__btn" @click="showAltTab();">{{ data.quiz.answer_2 }}
                        </div>
                    </div>

                    <div class="rm-widget__tab-question" v-if="displayAltTab">
                        {{ data.quiz.question_2 }}
                    </div>
                    <div class="rm-widget__input" v-if="displayAltTab" :class="{ empty: emptyInput }">
                        <div class="rm-widget__error" :style="{color: data.design.help_color}">Пожалуйста, заполните поле</div>
                        <textarea rows="4" placeholder="Введите ответ" v-model="posts.answer_2"></textarea>
                    </div>
                    <div class="rm-widget__input" v-if="displayAltTab && data.design.show_phone === 1">
                        <input type="text" placeholder="Введите номер телефона" v-model="posts.phone">
                    </div>
                    <div class="rm-widget__btn" v-if="displayAltTab" @click="checkEmpty();">Ответить</div>

                </div>

                <div class="rm-widget__tab rm-widget__tab--result" v-if="displayResult">
                    <div>{{ data.quiz.final_text }}</div>
                    <div class="rm-widget__like" :style="{ color: data.design.font_color}" @click="closeApp(); addStorageRecord()">
                        &#10084;
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

export default {
    name: 'App',
    data() {
        return {
            data: null,
            token: null,
            baseUrl: 'https://widget.43rm.ru',
            // baseUrl: 'http://feedback-widget.loc',
            posts: {
                question_1: null,
                answer_1: null,
                question_2: null,
                answer_2: null,
                phone: null,
                timeAndDate: null,
                screen: null,
                userIP: null,
                browser: null,
                device: null,
                fromPage: null,
                id: null,
            },
            emptyInput: false,
            displayApp: null,
            displayForm: false,
            displayResult: false,
            displayAltTab: false,
            displayTabs: true,
        }
    },
    methods: {
        showForm() {
            this.displayForm = !this.displayForm;
            if (this.data.metrika.open) {
                let open = this.data.metrika.open;
                eval(open);
            }
        },
        closeApp() {
            this.displayApp = !this.displayApp;
            if (this.data.metrika.close) {
                let close = this.data.metrika.close;
                eval(close);
            }
        },
        addStorageRecord() {
            sessionStorage.setItem(this.data.quiz.id, 'visited');
        },
        clickedYes() {
            this.posts.answer_1 = 'Да';
        },
        showResult() {
            this.displayTabs = !this.displayTabs;
            this.displayResult = !this.displayResult;
        },
        showAltTab() {
            this.posts.answer_1 = 'Нет';
            this.displayAltTab = !this.displayAltTab;
        },
        goBack() {
            this.displayAltTab = !this.displayAltTab;
            this.posts.answer_2 = null;
        },
        checkEmpty() {
            if (!this.posts.answer_2) {
                this.emptyInput = true;
            } else {
                this.emptyInput = false;
                this.showResult();
                this.postData();
                this.addStorageRecord();
            }
        },
        getInfo: function () {
            this.posts.question_1 = this.data.quiz.question_1;
            this.posts.question_2 = this.data.quiz.question_2;
            this.posts.id = this.data.quiz.id;

            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date + ' ' + time;
            this.posts.timeAndDate = dateTime;

            this.posts.screen = document.documentElement.clientWidth;

            let a = '';
            if (navigator.userAgent.search(/Safari/) > 0) {a = 'Safari'}
            if (navigator.userAgent.search(/Firefox/) > 0) {a = 'MozillaFirefox'}
            if (navigator.userAgent.search(/MSIE/) > 0 || navigator.userAgent.search(/NET CLR /) > 0) {a = 'Internet Explorer'}
            if (navigator.userAgent.search(/Chrome/) > 0) {a = 'Google Chrome'}
            if (navigator.userAgent.search(/YaBrowser/) > 0) {a = 'Яндекс браузер'}
            if (navigator.userAgent.search(/OPR/) > 0) {a = 'Opera'}
            if (navigator.userAgent.search(/Konqueror/) > 0) {a = 'Konqueror'}
            if (navigator.userAgent.search(/Iceweasel/) > 0) {a = 'Debian Iceweasel'}
            if (navigator.userAgent.search(/SeaMonkey/) > 0) {a = 'SeaMonkey'}
            if (navigator.userAgent.search(/Edge/) > 0) {a = 'Microsoft Edge'}
            this.posts.browser = a;

            var userDeviceArray = [
                {device: 'Android', platform: /Android/},
                {device: 'iPhone', platform: /iPhone/},
                {device: 'iPad', platform: /iPad/},
                {device: 'Symbian', platform: /Symbian/},
                {device: 'Windows Phone', platform: /Windows Phone/},
                {device: 'Tablet OS', platform: /Tablet OS/},
                {device: 'Linux', platform: /Linux/},
                {device: 'Windows', platform: /Windows NT/},
                {device: 'Macintosh', platform: /Macintosh/}
            ];
            var platform = navigator.userAgent;
            function getPlatform() {
                for (var i in userDeviceArray) {
                    if (userDeviceArray[i].platform.test(platform)) {
                        return userDeviceArray[i].device;
                    }
                }
                return 'Неизвестная платформа!' + platform;
            }
            this.posts.device = getPlatform();

            // fetch('https://api.ipify.org?format=json')
            //     .then(x => x.json())
            //     .then(({ip}) => {
            //         this.posts.userIP = ip;
            //     });
            this.posts.fromPage = window.location.href;
        },

        postData() {
            if (this.data.metrika.send) {
                let send = this.data.metrika.send;
                eval(send);
            }
            this.getInfo();
            let url = this.baseUrl + '/widget/' + this.token + '/form';
            this.axios.post(url, {
                data: this.posts,
            },{headers: {'X-Requested-With': 'XMLHttpRequest'}
            }).then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err.response);
            });
        },

        init() {
            let url = this.baseUrl+'/widget/'+this.token+'/init';
            this.axios.get(url, {
                params: {url: location.pathname},
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }).then(res => {
                if (res.data.result === 'success' && sessionStorage.getItem(res.data.quiz.id) !== 'visited')
                // if (res.data.result === 'success')
                {
                    this.data = res.data;
                    setTimeout(() =>  this.displayApp = !this.displayApp, this.data.timeout*1000);
                } else {
                    console.log(res.data.result);
                }
            }).catch(err => {
                console.log(err.response);
            });
        }
    },
    mounted() {
        this.token = document.querySelector('rm-feedback-widget').dataset.token;
        this.init();
    }
}
</script>

<style lang="less" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.rm-widget {
    line-height: 1.15;
    font-weight: 400;
    * {
        box-sizing: border-box;
    }
    &.fontLato {
        font-family: 'Lato', sans-serif;
    }
    &.fontRoboto {
        font-family: 'Roboto', sans-serif;
    }
    &.topLeft {
        .rm-widget__popup-btn, .rm-widget__form.open {
            top: 10%;
            right: initial;
            bottom: initial;
            left: 2px;
        }
    }
    &.topRight {
        .rm-widget__popup-btn, .rm-widget__form.open {
            top: 10%;
            right: 2px;
            bottom: initial;
            left: initial;
        }
    }
    &.leftBottom {
        .rm-widget__popup-btn, .rm-widget__form.open {
            top: initial;
            right: initial;
            bottom: 10%;
            left: 2px;
        }
    }
    &.rightBottom {
        .rm-widget__popup-btn, .rm-widget__form.open {
            top: initial;
            right: 2px;
            bottom: 10%;
            left: initial;
        }
    }
    &__popup-btn {
        color: #f1f1f1;
        max-width: 130px;
        position: fixed;
        right: 2px;
        bottom: 35%;
        box-shadow: 0 1px 3px rgb(0, 0, 0, 0.25);
        border-radius: 12px;
        z-index: 999999;
        animation: puls 2s infinite;
        cursor: pointer;

        div {
            padding: 8px 12px;
            text-align: center;
            font-weight: bold;
        }

        &:hover {
            animation: none;
        }
    }
    &__title {
        font-weight: 500;
        color: #1c1b1b;
        text-align: center;
        margin: 0 24px;
        width: 100%;
        img {
            object-fit: contain;
            width: 100%;
        }
    }
    &__form {
        background-color: #ffffff;
        box-shadow: 0 1px 3px rgb(0, 0, 0, 0.25);
        border-radius: 12px;
        width: 290px;
        max-width: 290px;
        margin-left: auto;
        overflow: visible;
        z-index: 1000000;
        opacity: 0;
        &.open {
            opacity: 1;
            display: block;
            position: fixed;
            bottom: 20%;
            animation: open 0.5s linear;
            right: 5px;
        }
    }
    &__header {
        color: #000000;
        display: flex;
        align-items: center;
        padding: 5px 14px;
    }
    &__body {
        padding: 14px;
        align-items: center;
        color: #f1f1f1;
        border-radius: 0 0 12px 12px;
    }
    &__close-btn, &__tab-count {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__tab-count {
        opacity: 0.35;
    }
    &__close-btn {
        opacity: 0.8;
        font-size: 1.2em;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }
    &__back-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        margin-top: -8px;
        font-size: 0.9em;
        span {
            font-size: 60px;
            width: 35px;
            height: 35px;
            margin: 0 0 0 18px;
            display: flex;
            justify-content: center;
        }
    }
    &__tab {
        display: block;
        margin: 0 auto;
        animation: open 0.3s linear;

        &-question {
            margin-bottom: 10px;
        }
        &--result {
            text-align: center;
        }
    }
    &__btn-wrapper {
        display: flex;
        flex-direction: column;
        padding: 18px 0 10px 0;
    }
    &__btn {
        background-color: #ffffff;
        font-weight: 400;
        color: #1c1b1b;
        border: none;
        padding: 10px 20px;
        text-align: center;
        width: 100%;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        &:last-child {
            margin-top: 18px;
        }
        &:hover {
            opacity: 0.85;
        }
    }
    &__input {
        width: 100%;
        margin: 0 auto;
        padding: 10px 0 0 0;

        textarea {
            align-items: center;
            width: 100%;
            border-radius: 5px;
            outline: none;
            border: none;
            padding: 6px;
            resize: none;
            font-size: inherit;
            color: #000000;
        }

        button {
            background-color: #ffffff;
            font-weight: 700;
            color: #1c1b1b;
            border: none;
            outline: none;
        }
        input {
            border: none;
            outline: none;
            padding: 6px;
            width: 100%;
            border-radius: 5px;
            font-size: inherit;
            color: #000000;
        }
        &.empty {
            .rm-widget__error {
                display: block;
            }
        }
    }
    &__error {
        display: none;
        padding: 5px 0;
        font-size: 16px;
    }
    &__like {
        cursor: pointer;
        margin: 0 auto;
        font-size: 40px;
        width: 36px;
        height: 36px;
        color: #f1f1f1;
    }
}
@-webkit-keyframes puls {
    0% { transform: translateX(0) }
    25% { transform: translateX(2px) }
    50% { transform: translateX(-3px) }
    75% { transform: translateX(2px) }
    100% { transform: translateX(0) }
}

@keyframes open {
    0% {
        display: block;
        opacity: 0;
        visibility: hidden;
    }
    100% {
        display: block;
        opacity: 1;
        visibility: visible;
    }
}
</style>
